<div class="grid-view" *ngIf="isGridView$ | async; else listView">
    <a class="card" routerLink="/{{ event.slug || 'error-page' }}">
        <div class="card-header">
            <img [src]="event.image || 'assets/download.png'" [alt]="event.title + ' image'" loading="lazy"/>
        </div>
        <div class="card-body events">
            <h4>{{ event.title }}</h4>
            <div class="">
                <div class="d-flex mt-3" style="justify-content: space-between">
                    <p>{{ event.date }}</p>
                    <p>&nbsp;·&nbsp;</p>
                    <p>{{ event.num_lectures }} videos</p>
                </div>
            </div>
        </div>
    </a>
</div>
<ng-template #listView>
    <div class="table">
      <a class="event-list-view" routerLink="/{{ event.slug || 'error-page' }}">
            <img [src]="event.image || 'assets/download.png'" alt="video-img" loading="lazy"/>
            <div class="right">
                <strong class="event-title">{{ event.title }}</strong>
                <div class="numbers">
                    <span class="light-color">{{ event.date }}</span>
                    <span class="light-color">{{ event.num_lectures ?? 0 }} Videos</span>
                </div>
            </div>
        </a>
    </div>
</ng-template>
