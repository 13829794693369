import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { Event } from '../../../../../@domain/event/entities/event.interface';

@Component({
    selector: 'app-event-item',
    templateUrl: './event-item.component.html',
    styleUrls: [ './event-item.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, RouterLink, ImageDirective, AsyncPipe ]
})
export class EventItemComponent implements OnInit {
  @Input() event!: Event;
  @Input() listenToListView: boolean = true;
  @Input() onlyListView!: any;
  isGridView$!: Observable<boolean>;

  constructor(private itemsViewService: ItemsViewService) {
  }

  ngOnInit(): void {
    this.isGridView$ = this.setupGridView();
  }


  private setupGridView(): Observable<boolean> {
    if (this.onlyListView) {
      return of(false);
    }

    if (!this.listenToListView) {
      return of(true);
    }

    return this.itemsViewService.onChangeView();
  }

}
