import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, shareReplay, throwError } from 'rxjs';
import { Page } from '../../../@common/entities/page.interface';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { Logger } from '../../../@common/log/logger';
import { Event } from '../entities/event.interface';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private http = inject(HttpClient);
    private logger = inject(Logger);
    private router = inject(Router);

    getAllEvents(
        hits: string,
        limit: number,
        offset: number,
        sort: string,
        year: string,
        upload: boolean = false
    ): Observable<Page<Event>> {
        if (sort !== 'date' && sort !== 'caption') {
            sort = 'date';
        }

        return this.http.get<Page<Event>>(
            `${ ApiPath.events }/filter/?hits=${ hits }&limit=${ limit }&offset=${ offset }&sort=${ sort }&year=${ year }&images=${ !upload }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get all events failed', e);
                return throwError(() => e);
            })
        );
    }

    getLatestEvents(): Observable<Page<Event>> {
        return this.http.get<Page<Event>>(
            `${ ApiPath.events }/filter/?hits=descending&sort=date&limit=3&offset=0`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get latest events failed', e);
                return throwError(() => e);
            })
        );
    }

    getEvent(slug: string): Observable<Event> {
        return this.http.get<Event>(
            `${ ApiPath.events }/${ slug }/filter_lectures/`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                if (e.status === 403) {
                    this.router.navigate([ '/unauthorized' ]);
                } else {
                    this.logger.apiError('Get event with slug failed', e);
                }
                return throwError(() => e);
            })
        );
    }

    getUpcomingEvents(): Observable<Page<Event>> {
        return this.http.get<Page<Event>>(`${ ApiPath.events }/upcoming/`, { withCredentials: true }).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get upcoming events failed', e);
                return throwError(() => e);
            })
        );
    }

}
